input[type="number"] {
    width:5vw;
    margin: 0 .75rem;
    border-radius: 8px;
    
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"]:not(:placeholder-shown){
    background-color: var(--highlight-color);
}