.board {
  padding: 4rem 3%;
  min-height: calc(100vh - 2.3rem);
  box-sizing: border-box;


  .filters{
    display:flex;
    align-items: center;
    background-color: #FFF;
    margin-block:1rem;
    padding: 1rem 2rem;
    border-radius: .5rem;
    form{
      display:flex;
    }

    label{
      display: flex;
      align-items: center;
      margin-right:5rem;

      input{
        display: inline;
        height:15px;
        width: 15px;
        appearance: auto;
      }
    }
  }

  .filters-box {
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    margin-bottom: 24px;
    background: white;
    flex-wrap: wrap;

    .filters-title {
      width: 100%;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .date-range {
      display: flex;
    }

    .remove-border-right {
      border-right: none;
    }

    label {
      flex-grow: 10;
      width: calc(100% / 2);
      margin-bottom: 1rem;
      select,
      input {
        width: 500px;
        border-radius: 3px;
      }
    }

    h3 {
      width: 100%;
      margin-bottom: 0;
    }
    .btn-gral {
      font-size: 14px;
      height: 30px;
      line-height: 31px;
      padding: 0 21px;
      border-radius: 5px;
      margin-left: auto;
      align-self: flex-end;
      margin-bottom: 1rem;
    }
    .predefined-options {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      gap: 10px;
      margin-top: 1rem;
      margin-bottom: 1rem;

      button {
        font-size: 14px;
        height: 30px;
        line-height: 31px;
        padding: 0 21px;
        border-radius: 5px;
        background-color: #eee;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  .results-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 50px;
    grid-template-areas: "left-column right-column";

    .right-column {
      grid-area: right-column;
    }
    .left-column {
      grid-area: left-column;
    }
  }
}

.content-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8% auto;

  .items-empty {
    display: flex;
    flex-direction: column;
    h4 {
      margin: 12px 0 0;
      font-size: 14px;
      letter-spacing: 1px;
      color: #333a46;
      text-transform: uppercase;
    }
    img {
      width: 7rem;
    }
  }
}
