.container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.login-title {
  text-align: center;
  margin: 0;
  width: 100%;
  margin-top: 10rem;
  text-transform: uppercase;
  font-size: 26px;
}

.login {
    background-color: white;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px20px 0px rgb(239, 239, 239);
    display: flex;
    justify-content: center;
    align-items: center;

    .line-form {
        margin: 1rem;
    }

    .line-button {
        text-align: center;
        margin: 2rem 0;
    }
}

.hidden {
  display: none;
}

@media screen and (max-width: 600px) {
  h1 {
    margin-top: 5rem;
  }
}
