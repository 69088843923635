.load-worked-time {
  display: flex;
  justify-content: space-between;

  .content-data-left {
    padding: 3rem;
    box-sizing: border-box;
    height: 100vh;
    width: 400px;
    background: white;
    position: fixed;
    border-right-width: 4px;
    border-left-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-style: solid;
    box-shadow: var(--box-shadow);
    border-image: linear-gradient(349deg, #b080e0 0%, rgba(228, 169, 95, 0.63) 51.04%, #72b0ab 100%) 1;
    overflow-y: auto;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 128%;
      letter-spacing: 0.23em;
      color: var(--color-font);
      margin: 0 0 3rem 0;
      width: 90%;
    }

    .history-box {
      margin-top: 3rem;
    }
  }

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: var(--color-font);
    display: block;
  }

  .project-data-right {
    width: 100%;
    padding: 5% 6%;
    box-sizing: border-box;
    background-color: var(--background-color-body);
    display: flex;
    flex-direction: column;
    padding-left: 456px;
    min-height: 100vh;

    h3 {
      margin-top: 0;
      margin-bottom: 3rem;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 52px;
      color: var(--color-font);

      &::before {
        display: block;
        content: '';
        height: 8px;
        width: 100px;
        background: linear-gradient(90deg, rgba(228,151,255,1) 50%, rgba(0,233,144,1) 50%);
        border-radius: 10rem;
      }
    }

    .button-side {
      align-self: flex-end;
      position: relative;
    }
  }

  .projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 1rem;
    border: 1px solid #AAA;
    border-radius: .5rem;

    button[type="button"] {
      padding: 1rem;
      border-radius: .5rem;
      background-color: var(--highlight-color);
      transition: background-color .25s ease-out;
      border: none;
      margin: 1rem;
    }

    button[type="button"]:hover {
      background-color: #06af79;
      cursor: pointer;
    }
  }

  .project-time {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2{
      font-weight: 100;
    }
  }

  .grid-box-projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 5rem;

    .entry-box,
    .increase-button {
      display: inline-block;
      background: #ffffff;
      border-radius: 7px;
      padding-block: 6%;
      box-sizing: border-box;
      transition: all 1s linear;
      animation: toOp 0.5s linear;
      position: relative;
      background-clip: border-box;
      border-radius: 0.25rem;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 10%);
      width: 100%;

    }

    @keyframes toOp {
      from {
        opacity: 0.5;
      }
    }

    .increase-button {
      border: none;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23828282B3' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
      border-radius: 15px;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      color: #979797;
      min-height: 210px;

      span {
        display: block;
        font-family: var(--font);
      }
    }

    .cross {
      appearance: none;
      cursor: pointer;
      border: none;
      background: none;
      position: absolute;
      top: 12px;
      right: 10px;
    }
  }

  .msg {
    display: block;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 0;
    margin: 0;
    top: -60px;
    width: max-content;
  }

  .error-msg {
    color: #e23e3d;
  }

  .success-msg {
    color: #198754;
  }
}

.footer-bottom {
  padding-left: 400px;
  box-sizing: border-box;
}

.project-info {

  .proyect-time {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;

    span {
      font-size: 2vh;
    }

    .time-selector {
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .minutes {
        justify-self: flex-start;
        width: 80%;
      }
    }

  }
}

.project-button.active {
  outline: 1px solid #000 !important;
}
