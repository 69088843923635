footer{
  width: 100%;
  height: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #383838;

  p{
    margin: 0;
    color: white;
    font-size: 12px;
  }
}
