:root {
  --font: "Reem Kufi", sans-serif;
  --purple: #e497ff;
  --highlight-color: #00e99e;
  --color-font: rgb(50, 58, 70);
  --background-color-body: #f2f5f7;
  --box-shadow: 0 2px 6px -2px rgb(0 0 0 / 16%), 0 2px 6px 0 rgb(0 0 0 / 12%);
  --background-body: #1a1a3c;
  --background-element: #2a2a4a;
  --color: #dedefd;
  --border: 1px solid rgba(0, 0, 0, 0.1);
  --font-table: rgb(108, 117, 125);
}

body {
  margin: 0;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color-body);
}

.box-form {
  margin-bottom: 1rem;

  &.double-form{
    display: flex;
    justify-content: space-between;

    label{
      width: 48%;
    }
  }
}

.today {
  color: rgb(247, 184, 75);
  background-color: #fdf1db;
  border: #fdeac9 solid 1px;
  border-radius: 4px;
}
.no-charge {
  color: #ababab;
}
.incomplete {
  color: #f1556c;
}
.complete {
  color: #1abc9c;
}

.title-table {
  margin: 0 0 1.5rem 0;
  font-weight: 400;
  font-size: 18px;

  button {
    float: right;
  }
}

select,
input {
  display: block;
  margin-top: 7px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #808080;
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
  font-family: var(--font);
  color: var(--color-font);
  padding: 0.45rem 2.7rem 0.45rem 0.9rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

select,
.inputData {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 14px 10px;
}

.inputData::-webkit-calendar-picker-indicator {
  display: none !important;
}

.last-box {
  display: flex;
  position: relative;
  justify-content: space-between;

  .resume {
    display: flex;
    flex-wrap: wrap;
    background: white;
    width: 250px;
    padding: 17px 25px 12px;
    box-sizing: border-box;
    flex-direction: column;
    text-align: left;
    border-radius: 0.25rem;

    h4 {
      width: 100%;
      margin: 0;
      color: var(--color-font);
      font-weight: 300;
      margin-bottom: 9px;
    }
    .box-resume {
      color: rgb(124 124 124);
      align-items: center;
      display: flex;
      margin-bottom: 9px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;

      span {
        display: flex;
        color: #ca3ffb;
        background-color: rgba(228, 153, 255, 0.2);
        border-radius: 2px;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-left: auto;
        padding: 0px 11px;
        font-weight: 300;
        text-transform: initial;
        min-width: 43px;
      }
    }
  }
}

.btn-gral {
  background-color: var(--highlight-color);
  font-family: var(--font);
  border-radius: 9px;
  height: 49px;
  border: none;
  width: fit-content;
  padding: 0 37px;
  font-size: 18px;
  cursor: pointer;

  &.small {
    font-size: 14px;
    height: 30px;
    line-height: 31px;
    padding: 0 21px;
    border-radius: 5px;
  }
}

h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 128%;
  letter-spacing: 0.23em;
  color: var(--color-font);
  margin: 0 0 4rem 0;
  width: 90%;
}

.table-container {
  padding: 25px;
  box-sizing: border-box;
  border-radius: 7px;
  background: white;
  color: var(--background-element);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  margin-bottom: 24px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th {
          text-align: left;
          font-weight: 800;
          padding: 0.5rem 1rem;
          white-space: nowrap;
          color: #343a40;
          border: solid #dee2e6 1px;
          text-transform: uppercase;
          font-size: 12px;

          &.small {
            width: 2rem;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background-color: rgb(0 0 0 / 6%);
          }
        }

        td {
          color: var(--font-table);
          padding: 0.8rem 1rem;
          font-size: 0.9rem;
          border: solid rgb(222, 226, 230) 1px;

          img {
            width: 1.2rem;
            height: 1.2rem;
            cursor: pointer;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .box-form {
    margin: 1rem auto;
    text-align: left;
  }
}

@media (max-width: 800px) {
  .last-box {
    flex-direction: column;
    flex-wrap: wrap;

    .resume {
      margin: 0 auto;
    }
  }
}
