.worked-times-projects-stats {
  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;

    th, td {
      text-align: left;
      padding: 0.5rem;
      border: 1px solid #ddd;
    }

    th {
      font-weight: bold;
      background-color: #f9f9f9;
    }

    td {
      vertical-align: middle;
    }

    .details-row {
      background-color: #f3f3f3;
      height: 70%;
      font-size: 0.9rem;
      color: #777;
    }
  }

  .internal-project-icon {
    font-size: 0.8rem;
    font-weight: bold;
    color: #888;
    margin-right: 0.5rem;
  }

  .expand-button {
    background-color: transparent;
    border: none;
    color: #555;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    margin-left: 0.5rem;
    float: left;
    margin-right: 10px;
  }

  .title-table {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .project-tag {
      width: 15px;
      height: 15px;
      margin-right: 20px;
    }
  }


  td:first-child {
    display: flex;
    align-items: center;
  }

  .objectives-cell {
    margin-left: 1.7rem;
  }

  .project-name {
    margin-right: 10px;
    color: #454545;
  }


  .project-row {
    color: #454545;
    background-color: #f9f9f9;
    border-left: 4px solid hsl(27, 89%, 64%);
  }

  .objective-row {
    background-color: #f9f9f9;
    border-left: 4px solid #6ed471;
  }

  .objective-person-row {
    border-left: 4px solid #6ed471;

    .details-row {
      margin-left: 4.9rem;
    }
  }

  .project-person-row {
    border-left: 4px solid hsl(27, 89%, 64%);

    .details-row {
      margin-left: 3.2rem;
    }
  }

  .project-tag, .objective-tag {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin-right: 5px;
  }

  .project-internal {
    background-color: #6ed471;
  }

  .project-external {
    background-color: hsl(27, 89%, 64%);
  }
}
