.comp-time-selector {
  animation: fadeIn .25s ease-out;
  display: flex;
  position: relative;
  width: 100%;
  padding-block: 1rem;

  &>svg {
    position: absolute;
    background-color: transparent;
    right: 0;
    bottom: 18%;
    border: none;
    transition: transform .5s ease-out;
    font-size: 1.75rem;
    transition: fill 200ms ease;
    padding-inline:2rem;

    &:hover{
      cursor: pointer;
      fill:#AAA;
    }
  }

  .project-name {
    margin: auto 2rem;
    font-size: 1.25rem;
    width:130px;
  }
  h4 {
    align-items: center;
    display: flex;
    margin-bottom: 9px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
  }

  p{
    font-size: .8rem;
  }
}

.project-info {
  border: 1px solid #AAA;
  border-radius: .5rem;
}

.time-selector {
  div {
    display: flex;
    align-items: center;
  }

  & > .minutos-label, & > .horas-label{
    color:#888;
  }

  .time-button {
    height: 2vw;
    width: 2vw;
    border-radius: 8px;
    border: 1px solid green;
    margin: .25rem;
    padding: initial;
    transition: background-color .25s ease-out;
  }

  button.active,
  input.active {
    background-color: var(--highlight-color);
    border-color: rgb(12, 107, 25);
    border-width: 2px;
  }

  .time-button:hover {
    cursor: pointer;
    background-color: var(--highlight-color);
  }

  @media (max-width: 1000px) {
    .footer-bottom {
      padding-left: 0;
      margin-top: 13%;
    }

    .project-data-right {
      padding-left: 6%;
      min-height: auto;

      .load-worked-time {
        flex-wrap: wrap;
        flex-direction: column;

        .content-data-left {
          width: 100%;
          height: auto;
          position: initial;
          text-align: center;
          padding: 5.4rem 0 3rem 0;
          border-right-width: 0;
          border-left-width: 0;
          border-top-width: 0;
          border-bottom-width: 4px;
          border-image: linear-gradient(78deg, #b080e0 0%, rgba(228, 169, 95, 0.63) 51.04%, #72b0ab 100%) 1;

          h2 {
            margin: 0 auto 2.2rem auto;
            width: 90%;
            font-size: 20px;
            line-height: 1.2;
          }
        }

        .msg {
          top: -30px;
        }

        .project-data-right {
          padding-left: 6%;
          min-height: auto;

          .button-side {
            align-self: center;
            margin-top: 3rem;
          }
        }
      }
    }
  }
}

@keyframes fadeIn{
  from{
    top:-20px;
    opacity: 0;
  }
  to{
    top:0;
    opacity: 1;
  }
}
