.worked-times-projects-stats .table-container {
  overflow-x: auto;
}

.worked-times-projects-stats table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.worked-times-projects-stats table th,
.worked-times-projects-stats table td {
  text-align: left;
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.worked-times-projects-stats table th {
  font-weight: bold;
  background-color: #f9f9f9;
}

.worked-times-projects-stats table td {
  vertical-align: middle;
}

.worked-times-projects-stats .internal-project-icon {
  font-size: 0.8rem;
  font-weight: bold;
  color: #888;
  margin-right: 0.5rem;
}

.worked-times-projects-stats .expand-button {
  background-color: transparent;
  border: none;
  color: #555;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.worked-times-projects-stats .title-table {
  margin-top: 0;
}

.expand-button {
  background-color: transparent;
  border: none;
  color: #555;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
  float: left;
  margin-right: 10px;
}

td:first-child {
  display: flex;
  align-items: center;
}

.person-row {
  color: #454545;
  border-left: 4px solid var(--purple);
}

.project-row {
  color: #454545;
  border-left: 4px solid hsl(27, 89%, 64%);
}

.objective-row {
  color: #454545;
  border-left: 4px solid hsl(27, 89%, 64%);
}

.project-row{
  color: #454545;
}

.objective-cell {
  margin-left: 1.7rem;
}

.project-tag {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
}

.project-internal {
  background-color:#6ed471;
}

.project-external {
  background-color: hsl(27, 89%, 64%);
}

.project-cell {
  display: flex;
  align-items: center;
}

.person-name {
  margin-right: 10px;
}

.person-name,
.person-hours,
.person-average {
  color: #454545;
}

.left-content {
  display: flex;
  align-items: center;
}

.people-tag {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  margin-right: 15px;
  background-color: var(--purple);
}

.title-table {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0;
}

.btn-gral.small {
  margin-left: auto;
}
