.title-chart{
  font-weight: 400;
  font-size: 18px;
  color: rgb(50, 58, 70);
}


.boxes-container-wrapper {
  background-color: white;
  padding: 1rem;
}

.boxes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;

  .boxes-item {
    text-align: center;
    color: #7c7c7c;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;
      flex: 1;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      text-transform: initial;
      margin: 0.5rem 0;
      flex: 1;
    }
  }
}


.chart-container {
  background-color: white;
  padding: 1rem;
}

.chart {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  overflow: hidden;

  .chart-item {
    height: 100%;
    font-size: 0.8rem;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &.chart-external {
      background-color: hsl(27, 89%, 64%);
    }

    &.chart-internal {
      background-color: #6ed471;
    }
  }
}

.chart-label {
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  white-space: nowrap;
}

.chart-legend {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.5rem;

  .chart-legend-item {
    text-align: center;
    color: #7c7c7c;

    h3 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 900;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      text-transform: initial;
      margin: 0.5rem 0;
    }
  }

  .chart-external {
    color: #ff8420;
  }

  .chart-internal {
    color: #4dc151;
  }
}

