.nav-bar {
  position: absolute;
  right: 4rem;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;

  a {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    height: 64px;
    width: 41px;
    align-items: flex-end;
    text-align: center;
    justify-content: center;
    background-color: transparent;

    img {
      display: block;
      margin-bottom: 8px;
      box-sizing: border-box;
    }

    &.active {
      background-color: var(--purple);

      img {
        filter: invert(1.5);
      }
    }
  }

  a:last-of-type {
    margin-right: 2.5rem;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: rgb(75, 75, 75);

    display: flex;
    height: 55px;
    width: 41px;
    align-items: flex-end;
    text-align: center;
    justify-content: center;
    gap: .3rem;

    img {
      height: 60%;
      width: 100%;
      display: block;
      margin-bottom: 0px;
      box-sizing: border-box;
      filter: invert(50%);
    }

    p {
      color: rgb(75, 75, 75);
      margin-bottom: .5px;
    }
  }

  button:hover {

    p {
      color: black;
    }

    img {
      filter: invert(0%);
    }
  }
}

@media (max-width: 800px) {
  .nav-bar {
    right: 1rem;
    top: -7px;
    transform: scale(0.8);
  }
}

@media (max-width: 1000px) {
  .nav-bar {
    right: 0rem;

    a:last-of-type {
      margin-right: 0rem;
    }

    button {
      p {
        display: none;
      }
    }
  }
}
