.historic {
  margin-top: 1rem;

  h4 {
    margin: 0;
    padding-bottom: 14px;
    border-bottom: 1px solid #9e9e9e;
    font-style: normal;
    font-weight: normal;
    color: var(--color-font);
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .days {
    width: 100%;

    .day-box {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0px 10px;

      p{
        margin: 6px 0;
      }
    }
  }
  .days :hover{
    background-color: rgb(72 233 158 / 32%);
    cursor: pointer;
  }
}
.content-loader-week{
  width: 100%;
  height: 35%;
  overflow: hidden;

  .content-loader{
    height: 100%;

    img{
      width: 4rem;
    }
  }
}

@media (max-width:700px) {
  .historic {
    .days {
      display: flex;
      justify-content: space-between;

      .day-box{
        flex-direction: column;
      }
    }
  }
}
