.select-options {
  padding-bottom: 1rem;
  & > span{
    font-weight: bold;
  }

    & > select {
      font-size: .9rem;
      color:#000;
      outline:2px solid var(--highlight-color);

      &>.placeholder{
        color:#AAA !important;
      }

      & > option{
        padding:1rem;
        border-bottom: 2px solid black;
        background-color: transparent;
      }

      &::-webkit-scrollbar{
        width:5px;
      }

      &::-webkit-scrollbar-thumb{
        background-color: #333;
        border-radius: 1rem;
      }
    }
  }
