.worked-times-objectives-stats .table-container {
  overflow-x: auto;
}

.worked-times-objectives-stats table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.worked-times-objectives-stats table th,
.worked-times-objectives-stats table td {
  text-align: left;
  padding: 0.5rem;
  border: 1px solid #ddd;
}

.worked-times-objectives-stats table th:first-child {
  width: 50%;
}

.worked-times-objectives-stats table th {
  font-weight: bold;
  background-color: #f9f9f9;
}

.worked-times-objectives-stats table td {
  vertical-align: middle;
}

.worked-times-objectives-stats .internal-objective-icon {
  font-size: 0.8rem;
  font-weight: bold;
  color: #888;
  margin-right: 0.5rem;
}

.worked-times-objectives-stats .expand-button {
  background-color: transparent;
  border: none;
  color: #555;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.worked-times-objectives-stats .title-table {
  margin-top: 0;
}

.title-table {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 0;
}


.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .objective-tag {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }
}

.expand-button {
  float: left;
  margin-right: 10px;
}

td:first-child {
  display: flex;
  align-items: center;
}
.objectives-cell {
  display: flex;
  align-items: center;
}

.objective-title {
  color: #454545;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20rem;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  font: inherit;
}

.objective-title.expanded {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}

.objective-details{
  color: #454545;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.objectives-row{
  border-left: 4px solid #6ed471;
}

.objective-tag {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
}

.objective-internal {
  background-color:#6ed471;
}

.objective-external {
  background-color: hsl(27, 89%, 64%);
}

.worked-times-objectives-stats table .details-row {
  height: 70%;
}
